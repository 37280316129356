///
/// Create global CSS variables from Sass ones
///
/// @param {string} $name - CSS variable name prefix
/// @param {*|map} $value - Value or a map of values
/// @requires $namespace
///
@mixin create-global($name, $value) {
  $result: ();
  $prefix: if($namespace, '#{$namespace}-', '');

  @if type-of($value) == map {
    @each $name-1, $value-1 in $value {
      @if type-of($value-1) == map {
        @each $name-2, $value-2 in $value-1 {
          $result: map-merge($result, (
            #{inspect(--#{$prefix}#{$name}--#{$name-1}--#{$name-2})}: #{inspect($value-2)}
          ));
        }
      }
      @else {
        $result: map-merge($result, (
          #{inspect(--#{$prefix}#{$name}--#{$name-1})}: #{inspect($value-1)}
        ));
      }
    }
  }
  @else {
    $result: (#{inspect(--#{$prefix}#{$name})}: #{$value});
  }

  :root {
    @each $var, $val in $result {
      #{$var}: #{$val};
    }
  }
}


///
/// Add ellipsis
///
/// {bool} $max-width - If specified, sets max-width
///
@mixin ellipsis($max-width: false) {
  @if $max-width { max-width: $max-width; }

  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


///
/// Animate appearance
///
/// {number} $timeout
///
@mixin fade-in($timeout: 200ms) {
  animation: fade-in $timeout ease-in forwards;
}

///
/// Animate disappearance
///
/// {number} $timeout
///
@mixin fade-out($timeout: 200ms) {
  animation: fade-out $timeout ease-out forwards;
}


///
/// Set or removes a focus of an element by applying a box-shadow
///
/// @param {map|null} $options - box-shadow options (inset x y blur spread color)
/// @requires $focus-shadow
///
@mixin focus($options: null) {
  outline: none;
  text-decoration: none;

  @if $options == none {
    box-shadow: none;
  }
  @else {
    $inset: map-get($focus-shadow, inset);
    $x: map-get($focus-shadow, x);
    $y: map-get($focus-shadow, y);
    $blur: map-get($focus-shadow, blur);
    $spread: map-get($focus-shadow, spread);
    $color: map-get($focus-shadow, color);

    @if type-of($options) == map {
      $inset: if(map-has-key($options, inset), map-get($options, inset), $inset);
      $x: if(map-has-key($options, x), map-get($options, x), $x);
      $y: if(map-has-key($options, y), map-get($options, y), $y);
      $blur: if(map-has-key($options, blur), map-get($options, blur), $blur);
      $spread: if(map-has-key($options, spread), map-get($options, spread), $spread);
      $color: if(map-has-key($options, color), map-get($options, color), $color);
    }

    box-shadow: #{if($inset == true, inset, '')} $x $y $blur $spread $color;
  }
}


///
/// Sets or removes a background
///
/// @param {map|list|null} $background - Map of background options (color, image, repeat, position, size, clip, origin, attachment)
///
@mixin set-background($background: null) {
  $background: if(not $background, none, $background);

  @if type-of($background) != map {
    background: $background;
  }
  @else {
    @if map-has-key($background, color) {
      background-color: map-get($background, color);
    }
    @if map-has-key($background, image) {
      background-image: map-get($background, image);
    }
    @if map-has-key($background, repeat) {
      background-repeat: map-get($background, repeat);
    }
    @if map-has-key($background, position) {
      background-position: map-get($background, position);
    }
    @if map-has-key($background, size) {
      background-size: map-get($background, size);
    }
    @if map-has-key($background, clip) {
      background-clip: map-get($background, clip);
    }
    @if map-has-key($background, origin) {
      background-origin: map-get($background, origin);
    }
    @if map-has-key($background, attachment) {
      background-attachment: map-get($background, attachment);
    }
  }
}


///
/// Sets border radius
///
/// @param {number} $radius Border radius
/// @param {string} $side Border sides
/// @requires $default-border-radius
///
@mixin set-radius($radius, $side: all) {
  $radius: if($radius, $radius, if($default-border-radius, $default-border-radius, 4px));

  @if $side == top {
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
  }
  @else if $side == right {
    border-top-right-radius: $radius;
    border-bottom-right-radius: $radius;
  }
  @else if $side == bottom {
    border-bottom-right-radius: $radius;
    border-bottom-left-radius: $radius;
  }
  @else if $side == left {
    border-top-left-radius: $radius;
    border-bottom-left-radius: $radius;
  }
  @else {
    border-radius: $radius;
  }
}


///
/// Sets a border
///
/// @param {map|list} $border - Border options (side, width, style, color, radius, image)
///
@mixin set-border($border) {
  $side: all;

  @if (type-of($border) == map) {
    $side: if(map-has-key($border, side), map-get($border, side), all);
  }

  $sufix: if(index($side, all) != null, '', '-' + $side);

  // if a simple border is passed, just apply it
  @if type-of($border) != map {
    border#{$sufix}: $border;
  }
  // if a border as a map is passed
  @else {
    @if map-has-key($border, width) {
      border#{$sufix}-width: map-get($border, width);
    }
    @if map-has-key($border, style) {
      border#{$sufix}-style: map-get($border, style);
    }
    @if map-has-key($border, color) {
      border#{$sufix}-color: map-get($border, color);
    }
    @if map-has-key($border, radius) {
      //border#{$sufix}-radius: map-get($border, radius);
      @include set-radius(map-get($border, radius), $sufix);
    }
    @if map-has-key($border, image) {
      border-image: map-get($border, image);
    }
  }
}


///
/// Sets the font properties
///
/// @param {map|list|number|string|null} $font - Map of font options
///
@mixin set-font($font: null) {
  $type: type-of($font);

  @if $type == list {
    font: $font;
  }
  @else if $type == number {
    font-size: $font;
  }
  @else if $type == string {
    font-size: map-get($font-sizes, $font);
  }
  @else {
    @if map-has-key($font, style) {
      font-style: map-get($font, style);
    }
    @if map-has-key($font, variant) {
      font-variant: map-get($font, variant);
    }
    @if map-has-key($font, weight) {
      font-weight: map-get($font, weight);
    }
    @if map-has-key($font, size) {
      $size: map-get($font-sizes, map-get($font, size));
      font-size: if($size, $size, map-get($font, size));
    }
    @if map-has-key($font, height) {
      line-height: map-get($font, height);
    }
    @if map-has-key($font, family) {
      font-family: map-get($font, family);
    }
    @if map-has-key($font, color) {
      $color: map-get($font, color);
      color: if($color, $color, color(color));
    }
    @if map-has-key($font, align) {
      text-align: map-get($font, align);
    }
    @if map-has-key($font, transform) {
      text-transform: map-get($font, transform);
    }
    @if map-has-key($font, decoration) {
      text-decoration: map-get($font, decoration);
    }
  }
}


///
/// New mixing for arrows
///
/// @param {string} $direction - Triangle direction, either `top`, `right`, `bottom` or `left`
/// @param {color} $color [currentcolor] - Triangle color
/// @param {number} $size [1em] - Triangle size
///
@mixin arrow($direction, $color, $size) {
  content: '';
  display: block;
  height: 0;
  width: 0;

  @if $direction == 'top' {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
  } @else if $direction == 'right' {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
  } @else if $direction == 'bottom' {
    border-top: $size solid $color;
    border-right: $size solid transparent;
    border-left: $size solid transparent;
  } @else if $direction == 'left' {
    border-top: $size solid transparent;
    border-right: $size solid $color;
    border-bottom: $size solid transparent;
  } @else if $direction == 'top-left' {
    border-top: $size solid $color;
    border-right: $size solid transparent;
  } @else if $direction == 'top-right' {
    border-top: $size solid $color;
    border-left: $size solid transparent;
  } @else if $direction == 'bottom-left' {
    border-bottom: $size solid $color;
    border-right: $size solid transparent;
  } @else if $direction == 'bottom-right' {
    border-bottom: $size solid $color;
    border-left: $size solid transparent;
  }
}


///
/// Mixin to manage responsive breakpoints
///
/// @param {string} $breakpoint - Breakpoint name
/// @require $breakpoints
///
@mixin responsive($screen-sizes...) {
  $breakpoints: if($breakpoints, $breakpoints, (
    'phone': (min-width: 320px, max-width: 767px),
    'tablet': (min-width: 768px, max-width: 1279px),
    'mobile': (min-width: 320px, max-width: 1279px),
    'desktop': (min-width: 1280px),
  ));

  $keys: map-keys($breakpoints);

  @if $screen-sizes == null or length($screen-sizes) == 0 {
    //@debug "Please provide at least one of the available breakpoints: #{$keys}";
    @error 'Please provide at least one of the available breakpoints: #{$keys}';
  }

  @each $size in $screen-sizes {
    $bounds: map-get($breakpoints, $size);

    // if the key doesn't exist in the map
    @if not $bounds {
      @error 'Unfortunately, no value could be retrieved for `#{$size}`. '
      + 'Available breakpoints are: `#{$keys}`.';
    }

    $condition: null;
    $min: null;
    $max: null;

    @if map-has-key($bounds, min-width) {
      $min: (min-width: map-get($bounds, min-width));
    }

    @if map-has-key($bounds, max-width) {
      $max: (max-width: map-get($bounds, max-width));
    }

    @if $min and not $max {
      $condition: #{inspect($min)};
    }
    @else if not $min and $max {
      $condition: #{inspect($max)};
    }
    @else if $min and $max {
      $condition: #{inspect($min)} unquote('and') #{inspect($max)};
    }
    @else {
      @warn 'No min-width or max-width defined.';
    }
    //@debug unquote('condition:') inspect($condition);

    // Prints a media query based on the value
    @media #{inspect($condition)} {
      @content;
    }
  }
}
