
@font-face {
  font-family: 'Museo';
  src: url('~@assets/fonts/museo/museo-light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-swap: swap;
}

@font-face {
  font-family: 'Museo';
  src: url('~@assets/fonts/museo/museo-regular.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-swap: swap;
}

@font-face {
  font-family: 'Museo';
  src: url('~@assets/fonts/museo/museo-bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-swap: swap;
}

@font-face {
  font-family: 'MuseoSans';
  src: url('~@assets/fonts/museo/museo-sans-light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-swap: swap;
}

@font-face {
  font-family: 'MuseoSans';
  src: url('~@assets/fonts/museo/museo-sans-regular.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-swap: swap;
}

@font-face {
  font-family: 'MuseoSans';
  src: url('~@assets/fonts/museo/museo-sans-bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-swap: swap;
}

@font-face {
  font-family: 'StreamlineIcons-Light';
  src: url('~@assets/fonts/StreamlineIcons-Light.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-swap: swap;
}
