///
/// Vertical rhythm related variables
///

// Default fonts
$base-font-family: 'MuseoSans', sans-serif !default;
$icon-font-family: 'StreamlineIcons-Light' !default;

// Document font size and line height
$base-font-size: 16px !default;
$base-line-height: 24px !default;

// Default border width and radius
$default-border-width: 1px !default;
$default-border-radius: 5px !default;

// Font sizes
$font-sizes: (
  'xxs': 10px,
  'xs': 12px,
  's': 14px,
  'base': $base-font-size,
  'm': $base-font-size,
  'l': 18px,
  'xl': 20px,
  'xxl': 36px,
  'h1': 36px,
  'h2': 24px,
  'h3': 20px,
  'h4': 16px,
  'h5': 14px,
  'h6': 12px,
  ) !default;

// Icon spacing
$icon-spacing: 8px !default;

// Responsive breakpoints
$breakpoints: (
  'phone': (max-width: 767px),
  'phablet': (min-width: 768px, max-width: 1023px),
  'tablet': (min-width: 1024px, max-width: 1199px),
  'mobile': (max-width: 1199px),
  'desktop': (min-width: 1200px),
  ) !default;

// Opacity for disabled elements
$disabled-opacity: .6 !default;

// Focus shadow around elements
$focus-shadow: (
  inset: false,
  x: 0,
  y: 0,
  blur: 1px,
  spread: 1px,
  color: color('link'),
  ) !default;

// Base text color
$base-text-color: color('text') !default;

// Link styling
$link-config: (
  color: color('secondary'),
  decoration: underline,
  hover: (color: color('primary'),
    decoration: none,
  ),
  ) !default;

// Window background
$window-background: (
  color: color('white'),
  image: linear-gradient(135deg, #ffffff 0%, #ffffff 33%, #f6f3f8 100%),
  repeat: no-repeat,
  attachment: fixed,
  ) !default;

// Selected text background
$selection-background: (
  color: color('highlight')) !default;

// Scrollbar styling
$scrollbar-config: (
  width: 10px,
  radius: 5px,
  background: #e9e9e9,
  thumb: #c0c0c0,
  hover: #a9a9a9,
  transition: background-color 0.9s linear,
  ) !default;


///
/// Global placeholders
///

%hidden {
  display: none !important;
}

%disabled {
  opacity: $disabled-opacity;
  pointer-events: none;
  cursor: not-allowed;
}

%focus {
  outline: none;
  text-decoration: none;
  box-shadow: #{if(map-get($focus-shadow, inset) == true, inset, '')} map-get($focus-shadow, x) map-get($focus-shadow, y) map-get($focus-shadow, blur) map-get($focus-shadow, spread) map-get($focus-shadow, color
);
}


:export {
  @each $name, $size in $font-sizes {
    #{inspect(font-size-#{$name})
  }

  : #{inspect($size)}
}

@each $name, $rule in $breakpoints {
  #{inspect(breakpoint-#{$name})
}

: #{inspect($rule)}
}
}

// New variables

// Colors
$white: #ffffff;
$primary: #d89f5a;
$secondary: #7d8f80;
$tertiary: #202e42;
$text: #172b4d;
$success: #3f7d20;
$error: #f6511d;
$warning: #ffb400;
$toDo: #BEBEBE;
$inProgress: #7494ea;

// Filter colors - for SVG styling
$filter-primary: invert(61%) sepia(56%) saturate(391%) hue-rotate(353deg) brightness(99%) contrast(88%);
$filter-secondary: invert(59%) sepia(8%) saturate(573%) hue-rotate(78deg) brightness(92%) contrast(84%);
$filter-text: invert(14%) sepia(13%) saturate(5832%) hue-rotate(193deg) brightness(85%) contrast(91%);
$filter-white: invert(100%) brightness(100%) saturate(100%);

$success-background: rgba(97, 191, 173, 0.06);
$error-background: rgba(231, 81, 82, 0.06);
$warning-background: rgba(255, 160, 15, 0.06);

// Font family
$base-font-family: 'MuseoSans', sans-serif !default;
$serif-font-family: 'Museo', sans-serif !default;

// Font size
$base-font-size: 16px;

// Font weight
$light-font: 300;
$regular-font: 500;
$bold-font: 700;

// Layout
$basic-padding: 24px;
$wider-padding: 30px;

@mixin readonly() {
  background-color: #fff;
  background-image: none;
  border-color: #7d8f80;
  color: #7d8f80;
  cursor: default;
}