$debug: true;
@use "sass:math";
///
/// Global class prefix - DON'T CHANGE!!!
///

$namespace: 'acl';


///
/// Color palette
///

$colors: (
  'primary': #d89f5a,
  'secondary': #7d8f80,
  'tertiary': #202e42,
  'text': #172b4d,

  'highlight': #fff3cd,

  'success': #61bfad,
  'error': #e75152,
  'warning': #FFA00F,

  'black': #181b1c,
  'white': #ffffff,

  'red': #d51923,
  'orange': #ffc107,
  'yellow': #eed974,
  'green': #5ec232,
  'darkblue': #262d34,
  'blue': #2d73f5,
  'turquoise': #0093ad,
  'cyan': #5cb7c7,
  'magenta': #aa46be,

  'gray': #6a7177,
  'lightgray': #dfe0e1,
  'smoke': #f4f6f8,
) !default;


///
/// Convert color to RGBA string
///
/// @param {color} $color - color to convert
/// @param {number} $alpha - opacity: 0 to 1
/// @return {string} Stringified rgba value
///
@function to-rgba($color, $alpha: null) {
  $red: round(red($color));
  $green: round(green($color));
  $blue: round(blue($color));
  $alpha: if($alpha, $alpha, alpha($color));

  @return unquote('rgba(#{$red}, #{$green}, #{$blue}, #{$alpha})');
}


///
/// Slightly lighten a color
///
/// @access public
/// @param {color} $color - color to tint
/// @param {number} $percentage - percentage of `$color` in returned color
/// @return {color}
///
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}


///
/// Slightly darken a color
///
/// @access public
/// @param {color} $color - color to shade
/// @param {number} $percentage - percentage of `$color` in returned color
/// @return {color}
///
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}


///
/// Get color from the palette
///
/// @param {list} $color | $color, $modifier | $color, $modifier, $value
///   $color - color name from the $colors map
///   $modifier - alpha, tint, shade
///   $value - 0 to 100
/// @require ../../../assets/styles/abstracts/$namespace
/// @require ../../../assets/styles/abstracts/$colors
/// @return {color} CSS var color with a fallback in RGBA format
///
@function color($args...) {
  $arglen: length($args);

  @if $args == null or $arglen == 0 or $arglen == 2 or $arglen > 3 {
    @error 'Invalid number of arguments: expected 1 (color) or 3 (color, modifier, value).';
  }

  $name: nth($args, 1);
  $modifier: null;
  $value: null;
  $color: map-get($colors, $name);

  @if $arglen == 1 {
    @return $color;
  }
  @else {
    $modifier: nth($args, 2);
    $value: to-number(nth($args, 3));

    @if ($modifier == tint) {
      $color: to-rgba(tint($color, $value), 1);
    }
    @else if ($modifier == shade)  {
      $color: to-rgba(shade($color, $value), 1);
    }
    @else {
      $color: to-rgba($color, ($value * 0.01));
    }
  }

  @return $color;
}


:export {
  @each $name, $value in $colors {
    #{inspect(color-#{$name})}: #{inspect($value)}
  }
}
///
/// Vertical rhythm related variables
///

// Default fonts
$base-font-family: 'MuseoSans', sans-serif !default;
$icon-font-family: 'StreamlineIcons-Light' !default;

// Document font size and line height
$base-font-size: 16px !default;
$base-line-height: 24px !default;

// Default border width and radius
$default-border-width: 1px !default;
$default-border-radius: 5px !default;

// Font sizes
$font-sizes: (
  'xxs': 10px,
  'xs': 12px,
  's': 14px,
  'base': $base-font-size,
  'm': $base-font-size,
  'l': 18px,
  'xl': 20px,
  'xxl': 36px,
  'h1': 36px,
  'h2': 24px,
  'h3': 20px,
  'h4': 16px,
  'h5': 14px,
  'h6': 12px,
  ) !default;

// Icon spacing
$icon-spacing: 8px !default;

// Responsive breakpoints
$breakpoints: (
  'phone': (max-width: 767px),
  'phablet': (min-width: 768px, max-width: 1023px),
  'tablet': (min-width: 1024px, max-width: 1199px),
  'mobile': (max-width: 1199px),
  'desktop': (min-width: 1200px),
  ) !default;

// Opacity for disabled elements
$disabled-opacity: .6 !default;

// Focus shadow around elements
$focus-shadow: (
  inset: false,
  x: 0,
  y: 0,
  blur: 1px,
  spread: 1px,
  color: color('link'),
  ) !default;

// Base text color
$base-text-color: color('text') !default;

// Link styling
$link-config: (
  color: color('secondary'),
  decoration: underline,
  hover: (color: color('primary'),
    decoration: none,
  ),
  ) !default;

// Window background
$window-background: (
  color: color('white'),
  image: linear-gradient(135deg, #ffffff 0%, #ffffff 33%, #f6f3f8 100%),
  repeat: no-repeat,
  attachment: fixed,
  ) !default;

// Selected text background
$selection-background: (
  color: color('highlight')) !default;

// Scrollbar styling
$scrollbar-config: (
  width: 10px,
  radius: 5px,
  background: #e9e9e9,
  thumb: #c0c0c0,
  hover: #a9a9a9,
  transition: background-color 0.9s linear,
  ) !default;


///
/// Global placeholders
///

%hidden {
  display: none !important;
}

%disabled {
  opacity: $disabled-opacity;
  pointer-events: none;
  cursor: not-allowed;
}

%focus {
  outline: none;
  text-decoration: none;
  box-shadow: #{if(map-get($focus-shadow, inset) == true, inset, '')} map-get($focus-shadow, x) map-get($focus-shadow, y) map-get($focus-shadow, blur) map-get($focus-shadow, spread) map-get($focus-shadow, color
);
}


:export {
  @each $name, $size in $font-sizes {
    #{inspect(font-size-#{$name})
  }

  : #{inspect($size)}
}

@each $name, $rule in $breakpoints {
  #{inspect(breakpoint-#{$name})
}

: #{inspect($rule)}
}
}

// New variables

// Colors
$white: #ffffff;
$primary: #d89f5a;
$secondary: #7d8f80;
$tertiary: #202e42;
$text: #172b4d;
$success: #3f7d20;
$error: #f6511d;
$warning: #ffb400;
$toDo: #BEBEBE;
$inProgress: #7494ea;

// Filter colors - for SVG styling
$filter-primary: invert(61%) sepia(56%) saturate(391%) hue-rotate(353deg) brightness(99%) contrast(88%);
$filter-secondary: invert(59%) sepia(8%) saturate(573%) hue-rotate(78deg) brightness(92%) contrast(84%);
$filter-text: invert(14%) sepia(13%) saturate(5832%) hue-rotate(193deg) brightness(85%) contrast(91%);
$filter-white: invert(100%) brightness(100%) saturate(100%);

$success-background: rgba(97, 191, 173, 0.06);
$error-background: rgba(231, 81, 82, 0.06);
$warning-background: rgba(255, 160, 15, 0.06);

// Font family
$base-font-family: 'MuseoSans', sans-serif !default;
$serif-font-family: 'Museo', sans-serif !default;

// Font size
$base-font-size: 16px;

// Font weight
$light-font: 300;
$regular-font: 500;
$bold-font: 700;

// Layout
$basic-padding: 24px;
$wider-padding: 30px;

@mixin readonly() {
  background-color: #fff;
  background-image: none;
  border-color: #7d8f80;
  color: #7d8f80;
  cursor: default;
}
///
/// Check if value is a number
///
/// @param {number} $value - Value to check
/// @return {bool} - True of false
///

@function is-number($value) {
  @return type-of($value) == 'number';
}

///
/// Check if value length is in relative units
///
/// @param {number} $value - Value to check
/// @requires is-number()
/// @return {bool} - True of false
///
@function is-relative-length($value) {
  @return is-number($value) and index('em' 'ex' 'ch' 'rem' 'vw' 'vh' 'vmin' 'vmax', unit($value)) != null;
}

///
/// Check if value length is in absolute units
///
/// @param {number} $value - Value to check
/// @requires is-number()
/// @return {bool} - True of false
///
@function is-absolute-length($value) {
  @return is-number($value) and index('cm' 'mm' 'in' 'px' 'pt' 'pc', unit($value)) != null;
}

///
/// Check if value is a length
///
/// @param {number} $value - Value to check
/// @requires is-relative-length(), is-absolute-length()
/// @return {bool} - True of false
///
@function is-length($value) {
  @return is-relative-length($value) or is-absolute-length($value);
}


///
/// Check if value is a percentage
///
/// @param {number} $value - Value to check
/// @requires is-number()
/// @return {bool} - True of false
///
@function is-percentage($value) {
  @return is-number($value) and unit($value) == '%';
}

///
/// Check if value is a position
///
/// @param {number} $value - Value to check
/// @requires is-length(), is-percentage()
/// @return {bool} - True of false
///
@function is-position($value) {
  @return is-length($value) or is-percentage($value) or index('top' 'right' 'bottom' 'left' 'center', $value) != null;
}


///
/// Casts an expression into a string
///
/// @param {string | number} $value - Value to be converted
/// @return {number}
///
@function to-string($value) {
  @return inspect($value);
}


///
/// Casts a value into a boolean
///
/// @param {undefined | string | number | list} $value - Value to be converted
/// @return {number}
///
@function to-bool($value) {
  @return not(not $value or $value == '' or $value == 0 or $value == ());
}


///
/// Add `$unit` to `$value`
///
/// @param {number} $value - Value to add unit to
/// @param {string} $unit - String representation of the unit
/// @return {number} - `$value` expressed in `$unit`
///
@function to-length($value, $unit) {
  $units: (
    'px': 1px,
    'pt': 1pt,
    'pc': 1pc,
    'cm': 1cm,
    'mm': 1mm,
    'in': 1in,
    '%': 1%,
    'em': 1em,
    'ex': 1ex,
    'ch': 1ch,
    'rem': 1rem,
    'vw': 1vw,
    'vh': 1vh,
    'vmin': 1vmin,
    'vmax': 1vmax,
  );

  @if not index(map-keys($units), $unit) {
    @error 'Invalid unit `#{$unit}`.';
  }

  @return $value * map-get($units, $unit);
}


///
/// Casts a string into a number
///
/// @param {string | number} $value - Value to be parsed
/// @return {number}
///
@function to-number($value) {
  @if type-of($value) == 'number' {
    @return $value;
  }
  @else if type-of($value) != 'string' {
    @error 'Value for `to-number` should be a number or a string.';
  }

  $result: 0;
  $digits: 0;
  $minus: str-slice($value, 1, 1) == '-';
  $numbers: ('0': 0, '1': 1, '2': 2, '3': 3, '4': 4, '5': 5, '6': 6, '7': 7, '8': 8, '9': 9);

  @for $i from if($minus, 2, 1) through str-length($value) {
    $character: str-slice($value, $i, $i);

    @if not (index(map-keys($numbers), $character) or $character == '.') {
      @return to-length(if($minus, -$result, $result), str-slice($value, $i));
    }

    @if $character == '.' {
      $digits: 1;
    }
    @else if $digits == 0 {
      $result: $result * 10 + map-get($numbers, $character);
    }
    @else {
      $digits: $digits * 10;
      $result: $result + math.div(map-get($numbers, $character), $digits);
    }
  }

  @return if($minus, -$result, $result);
}


///
/// Remove the unit of a length
///
/// @param {number} $number - Number to remove unit from
/// @return {number} - Unitless number
///
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, $number * 0 + 1);
  }

  @return $number;
}


///
/// Convert a value from px or rem to the value in line heights (vertical rhythm)
///
/// @param {number} $value - Number in px/rem
/// @requires strip-unit()
/// @return {number} - Unitless number in line heights
///
@function to-lines($value, $font-size: $base-font-size, $line-height: $base-line-height) {
  $unit: unit($value);
  $font-size: if($font-size, $font-size, 16px);
  $line-height: if($line-height, $line-height, 24px);

  @if unitless($value) {
    @return $value;
  }
  @else if $unit == 'px' {
    @return math.div(strip-unit($value), strip-unit($line-height));
  }
  @else if $unit == 'rem' {
    @return math.div(strip-unit($value) * strip-unit($font-size), strip-unit($line-height));
  }
  @else {
    @return strip-unit($value);
  }
}


///
/// Covert a value in rems, ems, pts or % into pixels
/// NOTE: For converting from em or %, you should provide the current element's font-size
///
/// @param {number} $value - Value in rem, em, pt or %
/// @param {number} $font-size (optional) - Font size of the current element (default set to root font-size)
/// @param {number} $line-height (optional) - Line height (default set to root line-height)
/// @requires to-length(), strip-unit()
/// @return {number} Value in pixels
///
@function to-px($value, $font-size: $base-font-size, $line-height: $base-line-height) {
  $unit: unit($value);

  @if index('' 'rem' 'px' 'em' 'pt' '%', $unit) == null {
    @warn 'Please provide unitless value or value in rem, em, pt or %.';
  }

  $font-size: if($font-size, $font-size, 16px);

  @if $unit == '' {
    @return to-length($value * strip-unit($line-height), 'px');
  }
  @else if $unit == 'em' or $unit == 'rem' {
    @return to-length(strip-unit($value) * strip-unit($font-size), 'px');
  }
  @else if $unit == '%' {
    @return to-length(strip-unit($value) * strip-unit($font-size) * 0.01, 'px')
  }
  @else if $unit == 'pt' {
    @return to-length(strip-unit($value) * 1.3333, 'px')
  }

  @return $value;
}


///
/// Covert a value in pixels, ems, pts or % into rems
/// NOTE: For converting from em or %, you should provide the current element's font-size
///
/// @param {number} $value Value in px, em, pt or %
/// @param {number} $font-size (optional) - Font size of the current element (default set to root font-size)
/// @param {number} $line-height (optional) - Line height (default set to root line-height)
/// @requires to-length(), strip-unit()
/// @return {number} Value in rems
///
@function to-rem($value, $font-size: $base-font-size, $line-height: $base-line-height) {
  $unit: unit($value);

  @if index('' 'rem' 'px' 'em' 'pt' '%', $unit) == null {
    @warn 'Please provide unitless value or value in px, em, pt or %.';
  }

  @if $unit == '' {
    @return to-length(math.div($value * strip-unit($line-height), strip-unit($font-size)), 'rem');
  }
  @else if index('px' 'em' 'pt' '%', $unit) != null {
    @return to-length(math.div(strip-unit(to-px($value)), strip-unit($font-size)), 'rem');
  }

  @return $value;
}


///
/// Helper function to replace characters in a string
///
/// @param {string} $string - String to search in
/// @param {string} $search - Search term
/// @param {string} $replace - Replace text
/// @return {string}
///
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @return if($index,
    str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace),
    $string
  );
}


///
/// Map deep get
///
/// @param {map} $map - Map
/// @param {list} $keys - Key chain
/// @return {*} - Desired value
///
@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}


///
/// Deep set function to set a value in nested maps
///
/// @param {map} $map - Map
/// @param {list} $keys -  Key chain
/// @param {*} $value - Value to assign
/// @return {map}
///
@function map-deep-set($map, $keys, $value) {
  $maps: ($map,);
  $result: null;

  // If the last key is a map already
  // Warn the user we will be overriding it with $value
  @if type-of(nth($keys, -1)) == 'map' {
    @warn 'The last key you specified is a map; it will be overrided with `#{$value}`.';
  }

  // If $keys is a single key
  // Just merge and return
  @if length($keys) == 1 {
    @return map-merge($map, ($keys: $value));
  }

  // Loop from the first to the second to last key from $keys
  // Store the associated map to this key in the $maps list
  // If the key doesn't exist, throw an error
  @for $i from 1 through length($keys) - 1 {
    $current-key: nth($keys, $i);
    $current-map: nth($maps, -1);
    $current-get: map-get($current-map, $current-key);
    @if $current-get == null {
      @error 'Key `#{$key}` doesn\'t exist at current level in map.';
    }
    $maps: append($maps, $current-get);
  }

  // Loop from the last map to the first one
  // Merge it with the previous one
  @for $i from length($maps) through 1 {
    $current-map: nth($maps, $i);
    $current-key: nth($keys, $i);
    $current-val: if($i == length($maps), $value, $result);
    $result: map-merge($current-map, ($current-key: $current-val));
  }

  // Return result
  @return $result;
}


///
/// Returns an opposite position:
/// - top -> bottom, bottom -> top, left -> right, right -> left
///
/// @param {position} $from
/// @return {position}
///
@function opposite-position($from) {
  @if ($from == top) {
      @return bottom;
  } @else if ($from == bottom) {
      @return top;
  } @else if ($from == left) {
      @return right;
  } @else if ($from == right) {
      @return left;
  } @else if ($from == center) {
      @return center;
  }
}


///
/// Capitalizes a string
///
/// @param {string} $string
/// @return {string}
///
@function capitalize($string) {
  @return to-upper-case(str-slice($string, 1, 1)) + str-slice($string, 2);
}
///
/// Create global CSS variables from Sass ones
///
/// @param {string} $name - CSS variable name prefix
/// @param {*|map} $value - Value or a map of values
/// @requires $namespace
///
@mixin create-global($name, $value) {
  $result: ();
  $prefix: if($namespace, '#{$namespace}-', '');

  @if type-of($value) == map {
    @each $name-1, $value-1 in $value {
      @if type-of($value-1) == map {
        @each $name-2, $value-2 in $value-1 {
          $result: map-merge($result, (
            #{inspect(--#{$prefix}#{$name}--#{$name-1}--#{$name-2})}: #{inspect($value-2)}
          ));
        }
      }
      @else {
        $result: map-merge($result, (
          #{inspect(--#{$prefix}#{$name}--#{$name-1})}: #{inspect($value-1)}
        ));
      }
    }
  }
  @else {
    $result: (#{inspect(--#{$prefix}#{$name})}: #{$value});
  }

  :root {
    @each $var, $val in $result {
      #{$var}: #{$val};
    }
  }
}


///
/// Add ellipsis
///
/// {bool} $max-width - If specified, sets max-width
///
@mixin ellipsis($max-width: false) {
  @if $max-width { max-width: $max-width; }

  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


///
/// Animate appearance
///
/// {number} $timeout
///
@mixin fade-in($timeout: 200ms) {
  animation: fade-in $timeout ease-in forwards;
}

///
/// Animate disappearance
///
/// {number} $timeout
///
@mixin fade-out($timeout: 200ms) {
  animation: fade-out $timeout ease-out forwards;
}


///
/// Set or removes a focus of an element by applying a box-shadow
///
/// @param {map|null} $options - box-shadow options (inset x y blur spread color)
/// @requires $focus-shadow
///
@mixin focus($options: null) {
  outline: none;
  text-decoration: none;

  @if $options == none {
    box-shadow: none;
  }
  @else {
    $inset: map-get($focus-shadow, inset);
    $x: map-get($focus-shadow, x);
    $y: map-get($focus-shadow, y);
    $blur: map-get($focus-shadow, blur);
    $spread: map-get($focus-shadow, spread);
    $color: map-get($focus-shadow, color);

    @if type-of($options) == map {
      $inset: if(map-has-key($options, inset), map-get($options, inset), $inset);
      $x: if(map-has-key($options, x), map-get($options, x), $x);
      $y: if(map-has-key($options, y), map-get($options, y), $y);
      $blur: if(map-has-key($options, blur), map-get($options, blur), $blur);
      $spread: if(map-has-key($options, spread), map-get($options, spread), $spread);
      $color: if(map-has-key($options, color), map-get($options, color), $color);
    }

    box-shadow: #{if($inset == true, inset, '')} $x $y $blur $spread $color;
  }
}


///
/// Sets or removes a background
///
/// @param {map|list|null} $background - Map of background options (color, image, repeat, position, size, clip, origin, attachment)
///
@mixin set-background($background: null) {
  $background: if(not $background, none, $background);

  @if type-of($background) != map {
    background: $background;
  }
  @else {
    @if map-has-key($background, color) {
      background-color: map-get($background, color);
    }
    @if map-has-key($background, image) {
      background-image: map-get($background, image);
    }
    @if map-has-key($background, repeat) {
      background-repeat: map-get($background, repeat);
    }
    @if map-has-key($background, position) {
      background-position: map-get($background, position);
    }
    @if map-has-key($background, size) {
      background-size: map-get($background, size);
    }
    @if map-has-key($background, clip) {
      background-clip: map-get($background, clip);
    }
    @if map-has-key($background, origin) {
      background-origin: map-get($background, origin);
    }
    @if map-has-key($background, attachment) {
      background-attachment: map-get($background, attachment);
    }
  }
}


///
/// Sets border radius
///
/// @param {number} $radius Border radius
/// @param {string} $side Border sides
/// @requires $default-border-radius
///
@mixin set-radius($radius, $side: all) {
  $radius: if($radius, $radius, if($default-border-radius, $default-border-radius, 4px));

  @if $side == top {
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
  }
  @else if $side == right {
    border-top-right-radius: $radius;
    border-bottom-right-radius: $radius;
  }
  @else if $side == bottom {
    border-bottom-right-radius: $radius;
    border-bottom-left-radius: $radius;
  }
  @else if $side == left {
    border-top-left-radius: $radius;
    border-bottom-left-radius: $radius;
  }
  @else {
    border-radius: $radius;
  }
}


///
/// Sets a border
///
/// @param {map|list} $border - Border options (side, width, style, color, radius, image)
///
@mixin set-border($border) {
  $side: all;

  @if (type-of($border) == map) {
    $side: if(map-has-key($border, side), map-get($border, side), all);
  }

  $sufix: if(index($side, all) != null, '', '-' + $side);

  // if a simple border is passed, just apply it
  @if type-of($border) != map {
    border#{$sufix}: $border;
  }
  // if a border as a map is passed
  @else {
    @if map-has-key($border, width) {
      border#{$sufix}-width: map-get($border, width);
    }
    @if map-has-key($border, style) {
      border#{$sufix}-style: map-get($border, style);
    }
    @if map-has-key($border, color) {
      border#{$sufix}-color: map-get($border, color);
    }
    @if map-has-key($border, radius) {
      //border#{$sufix}-radius: map-get($border, radius);
      @include set-radius(map-get($border, radius), $sufix);
    }
    @if map-has-key($border, image) {
      border-image: map-get($border, image);
    }
  }
}


///
/// Sets the font properties
///
/// @param {map|list|number|string|null} $font - Map of font options
///
@mixin set-font($font: null) {
  $type: type-of($font);

  @if $type == list {
    font: $font;
  }
  @else if $type == number {
    font-size: $font;
  }
  @else if $type == string {
    font-size: map-get($font-sizes, $font);
  }
  @else {
    @if map-has-key($font, style) {
      font-style: map-get($font, style);
    }
    @if map-has-key($font, variant) {
      font-variant: map-get($font, variant);
    }
    @if map-has-key($font, weight) {
      font-weight: map-get($font, weight);
    }
    @if map-has-key($font, size) {
      $size: map-get($font-sizes, map-get($font, size));
      font-size: if($size, $size, map-get($font, size));
    }
    @if map-has-key($font, height) {
      line-height: map-get($font, height);
    }
    @if map-has-key($font, family) {
      font-family: map-get($font, family);
    }
    @if map-has-key($font, color) {
      $color: map-get($font, color);
      color: if($color, $color, color(color));
    }
    @if map-has-key($font, align) {
      text-align: map-get($font, align);
    }
    @if map-has-key($font, transform) {
      text-transform: map-get($font, transform);
    }
    @if map-has-key($font, decoration) {
      text-decoration: map-get($font, decoration);
    }
  }
}


///
/// New mixing for arrows
///
/// @param {string} $direction - Triangle direction, either `top`, `right`, `bottom` or `left`
/// @param {color} $color [currentcolor] - Triangle color
/// @param {number} $size [1em] - Triangle size
///
@mixin arrow($direction, $color, $size) {
  content: '';
  display: block;
  height: 0;
  width: 0;

  @if $direction == 'top' {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
  } @else if $direction == 'right' {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
  } @else if $direction == 'bottom' {
    border-top: $size solid $color;
    border-right: $size solid transparent;
    border-left: $size solid transparent;
  } @else if $direction == 'left' {
    border-top: $size solid transparent;
    border-right: $size solid $color;
    border-bottom: $size solid transparent;
  } @else if $direction == 'top-left' {
    border-top: $size solid $color;
    border-right: $size solid transparent;
  } @else if $direction == 'top-right' {
    border-top: $size solid $color;
    border-left: $size solid transparent;
  } @else if $direction == 'bottom-left' {
    border-bottom: $size solid $color;
    border-right: $size solid transparent;
  } @else if $direction == 'bottom-right' {
    border-bottom: $size solid $color;
    border-left: $size solid transparent;
  }
}


///
/// Mixin to manage responsive breakpoints
///
/// @param {string} $breakpoint - Breakpoint name
/// @require ../../../assets/styles/abstracts/$breakpoints
///
@mixin responsive($screen-sizes...) {
  $breakpoints: if($breakpoints, $breakpoints, (
    'phone': (min-width: 320px, max-width: 767px),
    'tablet': (min-width: 768px, max-width: 1279px),
    'mobile': (min-width: 320px, max-width: 1279px),
    'desktop': (min-width: 1280px),
  ));

  $keys: map-keys($breakpoints);

  @if $screen-sizes == null or length($screen-sizes) == 0 {
    //@debug "Please provide at least one of the available breakpoints: #{$keys}";
    @error 'Please provide at least one of the available breakpoints: #{$keys}';
  }

  @each $size in $screen-sizes {
    $bounds: map-get($breakpoints, $size);

    // if the key doesn't exist in the map
    @if not $bounds {
      @error 'Unfortunately, no value could be retrieved for `#{$size}`. '
      + 'Available breakpoints are: `#{$keys}`.';
    }

    $condition: null;
    $min: null;
    $max: null;

    @if map-has-key($bounds, min-width) {
      $min: (min-width: map-get($bounds, min-width));
    }

    @if map-has-key($bounds, max-width) {
      $max: (max-width: map-get($bounds, max-width));
    }

    @if $min and not $max {
      $condition: #{inspect($min)};
    }
    @else if not $min and $max {
      $condition: #{inspect($max)};
    }
    @else if $min and $max {
      $condition: #{inspect($min)} unquote('and') #{inspect($max)};
    }
    @else {
      @warn 'No min-width or max-width defined.';
    }
    //@debug unquote('condition:') inspect($condition);

    // Prints a media query based on the value
    @media #{inspect($condition)} {
      @content;
    }
  }
}
.#{$namespace}-distributor-details {
    &__chip {
        margin-right: 10px;
    }

    &__content {
        margin-bottom: 1rem;

        &-description {

            dt,
            dd {
                float: none !important;
            }
        }

        &-fundsDetails {
            .#{$namespace}-distributor-details__dl-row dt {
                min-width: 250px;
            }
        }
    }

    &__cards {
        display: grid;
        grid-column-gap: 1rem;
        grid-template-columns: auto 300px;

        .#{$namespace}-distributor-details__content {
            display: inline-grid;
            min-height: 275px;
        }

        &-noCrm {
            grid-template-columns: auto;

            .#{$namespace}-distributor-details__content {
                min-height: auto;
            }
        }

        @media only screen and (max-width: 1280px) {
            grid: none;
        }
    }

    &__actions {
        margin: 1rem 0 0rem;
        display: flex;
        justify-content: flex-end;
    }

    &__top-section {
        text-align: center;

        img {
            width: 130px;
        }

        p {
            font-size: 20px;
        }
    }

    dl {
        width: 100%;
        overflow: hidden;
        padding: 0;
        margin: 0;
    }

    &__dl-row {
        margin: 0.25rem 0;

        &:after {
            display: block;
            content: '';
            clear: both;
        }

        dt {
            float: left;
            text-align: left;
            min-width: 175px;
            margin: 0;
            font-weight: 500;
        }

        dt::after {
            content: ':';
        }

        dd {
            float: left;
            padding: 0;
            margin: 0;
            //white-space: pre-wrap;
        }
    }

    &__contact {
        text-align: center;

        h3 {
            width: 100%;
        }

        picture {
            width: 5rem;
            margin: 0 auto;
        }

        address {
            text-align: center;
            padding-top: 0.5rem;
        }

        &.#{$namespace}-contact__actions {
            margin: 0.5rem auto 0;
            width: 5rem;
            justify-content: space-between;
        }
    }

    &__subDps {
        overflow-y: scroll;
        max-height: 50vh;

        @media only screen and (max-width: 1024px) {
            .k-master-row>td {
                border: 0;
                padding-left: 120px;
                position: relative;
            }

            td:nth-of-type(1):before {
                content: "Sub-Distributor";
            }

            td:nth-of-type(2):before {
                content: "City";
            }

            td:nth-of-type(3):before {
                content: "Country";
            }
        }
    }
}