.acl-i-synchronize-arrow:before {
  content: "\e936";
}

.acl-i-copy:before {
  content: "\e935";
}

.acl-i-hyperlink:before {
  content: "\e934";
}

.acl-i-email-action-unread:before {
  content: "\e932";
}

.acl-i-phone:before {
  content: "\e933";
}

.acl-i-messages-bubble-double:before {
  content: "\e92f";
}

.acl-i-wifi-signal-1:before {
  content: "\e930";
}

.acl-i-navigation-arrows-right-1:before {
  content: "\e931";
}

.acl-i-performance-money-increase:before {
  content: "\e92c";
}

.acl-i-brain-chip:before {
  content: "\e92d";
}

.acl-i-school-teacher-correct:before {
  content: "\e92e";
}

.acl-i-time-clock-circle:before {
  content: "\e929";
}

.acl-i-alert-circle:before {
  content: "\e927";
}

.acl-i-check-11:before {
  content: "\e928";
}

.acl-i-view:before {
  content: "\e92a";
}

.acl-i-view-off:before {
  content: "\e92b";
}

.acl-i-loading:before {
  content: "\e926";
}

.acl-i-common-file-download:before {
  content: "\e91e";
}

.acl-i-common-file-text-upload:before {
  content: "\e91f";
}

.acl-i-folder-upload:before {
  content: "\e920";
}

.acl-i-upload-bottom:before {
  content: "\e921";
}

.acl-i-arrow-right-1:before {
  content: "\e922";
}

.acl-i-arrow-left-1:before {
  content: "\e923";
}

.acl-i-arrow-down-1:before {
  content: "\e924";
}

.acl-i-arrow-up-1:before {
  content: "\e925";
}

.acl-i-trash:before {
  content: "\e91c";
}

.acl-i-pencil:before {
  content: "\e91d";
}

.acl-i-email-action-read:before {
  content: "\e900";
}

.acl-i-question-circle:before {
  content: "\e901";
}

.acl-i-newspaper-fold:before {
  content: "\e902";
}

.acl-i-subtract-circle:before {
  content: "\e903";
}

.acl-i-add-circle:before {
  content: "\e904";
}

.acl-i-navigation-menu:before {
  content: "\e905";
}

.acl-i-navigation-menu-horizontal:before {
  content: "\e906";
}

.acl-i-navigation-menu-horizontal-1:before {
  content: "\e907";
}

.acl-i-remove:before {
  content: "\e908";
}

.acl-i-remove-circle:before {
  content: "\e909";
}

.acl-i-check-circle-1:before {
  content: "\e90a";
}

.acl-i-check-1:before {
  content: "\e90b";
}

.acl-i-check-2:before {
  content: "\e90c";
}

.acl-i-office-file-pdf:before {
  content: "\e90d";
}

.acl-i-arrange-number:before {
  content: "\e90e";
}

.acl-i-arrange-letter:before {
  content: "\e90f";
}

.acl-i-keyboard-arrow-right:before {
  content: "\e910";
}

.acl-i-keyboard-arrow-left:before {
  content: "\e911";
}

.acl-i-keyboard-arrow-next:before {
  content: "\e912";
}

.acl-i-keyboard-arrow-previous:before {
  content: "\e913";
}

.acl-i-download-bottom:before {
  content: "\e914";
}

.acl-i-add:before {
  content: "\e915";
}

.acl-i-subtract:before {
  content: "\e916";
}

.acl-i-zoom-out:before {
  content: "\e917";
}

.acl-i-zoom-in:before {
  content: "\e918";
}

.acl-i-search:before {
  content: "\e919";
}

.acl-i-alarm-bell:before {
  content: "\e91a";
}

.acl-i-logout-1:before {
  content: "\e91b";
}