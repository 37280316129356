@keyframes fade-in {
  0%   { opacity: 0; }
  66%  { opacity: 0; }
  100% { opacity: 1; }
}


@keyframes fade-out {
  100% { opacity: 1; }
  66%  { opacity: 1; }
  0%   { opacity: 0; }
}


// @keyframes fade-in {
//   from { opacity: 0; }
//   to   { opacity: 1; }
// }


// @keyframes fade-out {
//   from { opacity: 1; }
//   to   { opacity: 0; }
// }


@keyframes pulsate {
  0% {
    opacity: .25;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: .25;
  }
}
