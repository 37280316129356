///
/// Color palette
///

$colors: (
  'primary': #d89f5a,
  'secondary': #7d8f80,
  'tertiary': #202e42,
  'text': #172b4d,

  'highlight': #fff3cd,

  'success': #61bfad,
  'error': #e75152,
  'warning': #FFA00F,

  'black': #181b1c,
  'white': #ffffff,

  'red': #d51923,
  'orange': #ffc107,
  'yellow': #eed974,
  'green': #5ec232,
  'darkblue': #262d34,
  'blue': #2d73f5,
  'turquoise': #0093ad,
  'cyan': #5cb7c7,
  'magenta': #aa46be,

  'gray': #6a7177,
  'lightgray': #dfe0e1,
  'smoke': #f4f6f8,
) !default;


///
/// Convert color to RGBA string
///
/// @param {color} $color - color to convert
/// @param {number} $alpha - opacity: 0 to 1
/// @return {string} Stringified rgba value
///
@function to-rgba($color, $alpha: null) {
  $red: round(red($color));
  $green: round(green($color));
  $blue: round(blue($color));
  $alpha: if($alpha, $alpha, alpha($color));

  @return unquote('rgba(#{$red}, #{$green}, #{$blue}, #{$alpha})');
}


///
/// Slightly lighten a color
///
/// @access public
/// @param {color} $color - color to tint
/// @param {number} $percentage - percentage of `$color` in returned color
/// @return {color}
///
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}


///
/// Slightly darken a color
///
/// @access public
/// @param {color} $color - color to shade
/// @param {number} $percentage - percentage of `$color` in returned color
/// @return {color}
///
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}


///
/// Get color from the palette
///
/// @param {list} $color | $color, $modifier | $color, $modifier, $value
///   $color - color name from the $colors map
///   $modifier - alpha, tint, shade
///   $value - 0 to 100
/// @require $namespace
/// @require $colors
/// @return {color} CSS var color with a fallback in RGBA format
///
@function color($args...) {
  $arglen: length($args);

  @if $args == null or $arglen == 0 or $arglen == 2 or $arglen > 3 {
    @error 'Invalid number of arguments: expected 1 (color) or 3 (color, modifier, value).';
  }

  $name: nth($args, 1);
  $modifier: null;
  $value: null;
  $color: map-get($colors, $name);

  @if $arglen == 1 {
    @return $color;
  }
  @else {
    $modifier: nth($args, 2);
    $value: to-number(nth($args, 3));

    @if ($modifier == tint) {
      $color: to-rgba(tint($color, $value), 1);
    }
    @else if ($modifier == shade)  {
      $color: to-rgba(shade($color, $value), 1);
    }
    @else {
      $color: to-rgba($color, ($value * 0.01));
    }
  }

  @return $color;
}


:export {
  @each $name, $value in $colors {
    #{inspect(color-#{$name})}: #{inspect($value)}
  }
}