$html-heading-color: color('tertiary') !default;
$html-heading-font-family: $base-font-family !default;
$html-heading-font-weight: 500 !default;
$html-text-font-weight: 300 !default;
$html-paragraph-margin: 0 0 1rem 0 !default;
$html-hr-border: 1px solid color('smoke') !default;


///
/// Vertical rhythm setup
///
:root {
  font-size: $base-line-height;
  line-height: $base-line-height;
}

///
/// Document font family
///
body {
  font-size: $base-font-size;
  font-family: $base-font-family;
  font-weight: 300;
}


///
/// Links
///
a {
  text-decoration: map-get($link-config, 'decoration');
  color: map-get($link-config, 'color');

  &:hover {
    text-decoration: map-deep-get($link-config, 'hover', 'decoration');
    color: map-deep-get($link-config, 'hover', 'color');
  }
}


///
/// Headings
///
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $html-heading-font-family;
  font-weight: $html-heading-font-weight !important;
  color: $html-heading-color;
}

h1 {
  font-size: map-get($font-sizes, h1);
  line-height: 2rem;
  margin: 1rem 0;
  color: color('primary');
}

h2 {
  font-size: map-get($font-sizes, h2);
  line-height: 1rem;
  margin: 0.5rem 0;
}

h3 {
  font-size: map-get($font-sizes, h3);
  line-height: 1rem;
  margin: 0.5rem 0;
}

h4 {
  font-size: map-get($font-sizes, h4);
  line-height: 1rem;
  margin: 0.5rem 0;
}

h5 {
  font-size: map-get($font-sizes, h5);
  line-height: 1rem;
  margin: 0.5rem 0;
}

h6 {
  font-size: map-get($font-sizes, h6);
  line-height: 1rem;
  margin: 0.5rem 0;
}


///
/// Paragraph
///
p {
  margin: $html-paragraph-margin;
  font-weight: $html-text-font-weight;
}


small {
  font-size: map-get($font-sizes, 's');
}


///
/// Horizontal rule
///
hr {
  width: 100%;
  height: 0;
  margin: 1rem 0;
  border: 0;
  border-top: $html-hr-border;
}


///
/// Address tag
///
address {
  font-style: normal;
}

@media only screen and (max-width: 1024px) {
  h1 {
    font-size: 30px;
  }
}